import React from 'react';
import { ReactElement } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IFragmentFaqGrouping } from 'types/contentful';

import FaqQuestionAnswer from './FaqQuestionAnswer';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TAB_ID_PREFIX = 'scrollable-auto-tab';

function TabPanel(props: TabPanelProps): ReactElement {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className={`${value !== index ? 'hidden' : ''}`}
      id={`${TAB_ID_PREFIX}panel-${index}`}
      aria-labelledby={`${TAB_ID_PREFIX}-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number): unknown {
  return {
    id: `${TAB_ID_PREFIX}-${index}`,
    'aria-controls': `${TAB_ID_PREFIX}panel-${index}`,
  };
}

const tabStyles = makeStyles({
  root: {
    color: 'rgb(var(--tertiaryTextActual))',
    textTransform: 'unset',
    fontSize: '16px',
  },
  selected: {
    color: 'black',
    fontWeight: 600,
  },
});

export default function FaqMultipleGroupingMateriall({
  faqGroupings,
}: {
  faqGroupings: IFragmentFaqGrouping[];
}): ReactElement {
  const [value, setValue] = React.useState(0);
  const tabClasses = tabStyles();

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number): void => {
    setValue(newValue);
  };

  const groupingList: string[] = [];

  const groupFaqs = faqGroupings.map(({ fields: { heading, faqs } }, index) => {
    //Adds headers to an array to use after nested map
    groupingList.push(heading);

    const groupingFaqs = faqs.map(({ fields: { question, answer } }, i) => {
      return <FaqQuestionAnswer key={i} question={question} answer={answer} />;
    });

    return (
      <TabPanel key={index} value={value} index={index}>
        <div className="inline-block px-1 text-black">{groupingFaqs}</div>
      </TabPanel>
    );
  });

  const allFaqs = faqGroupings.map(({ fields: { faqs } }, index) => {
    const groupingFaqs = faqs.map(({ fields: { question, answer } }, i) => {
      return <FaqQuestionAnswer key={i} question={question} answer={answer} />;
    });

    return (
      <div key={index} className="inline-block px-1 text-black">
        {groupingFaqs}
      </div>
    );
  });

  const groupingLinks = groupingList.map((groupHeading, index) => {
    return (
      <Tab
        disableRipple
        disableFocusRipple
        disableTouchRipple
        classes={tabClasses}
        key={index}
        label={`${groupHeading}`}
        {...a11yProps(index)}
      />
    );
  });

  return (
    <div className="text-black lg:mx-32 md:mx-20 -mx-2 lg:mt-16 mt-10 md:pb-16 pb-8">
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: 'rgb(var(--tertiaryTextActual))',
            height: '3px',
          },
        }}
        variant="scrollable"
        scrollButtons="on"
        aria-label="Frequently Asked Questions"
      >
        {groupingLinks}
        <Tab
          disableRipple
          disableFocusRipple
          disableTouchRipple
          classes={tabClasses}
          label="See All"
          {...a11yProps(faqGroupings.length)}
        />
      </Tabs>
      <div className="mx-6 mt-4 md:mt-10">
        {groupFaqs}
        <TabPanel value={value} index={faqGroupings.length}>
          {allFaqs}
        </TabPanel>
      </div>
    </div>
  );
}
