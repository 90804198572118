import { ReactElement } from 'react';
import { IFragmentFaqGrouping } from 'types/contentful';
import { FAQPageJsonLd } from 'next-seo';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

interface FaqData {
  faqs: IFragmentFaqGrouping[];
}

export default function FaqData({ faqs }: FaqData): ReactElement {
  const formatted = faqs.flatMap((group) =>
    group.fields.faqs.map((item) => ({
      questionName: item.fields.question,
      acceptedAnswerText: documentToPlainTextString(item.fields.answer),
    }))
  );

  return <FAQPageJsonLd mainEntity={formatted} />;
}
