import { ReactElement } from 'react';
import { IFragmentFaqFields } from 'types/contentful';
import RichText from '../Richtext';

export default function FaqQuestionAnswer({
  question,
  answer,
}: Omit<IFragmentFaqFields, 'id'>): ReactElement {
  return (
    <div className="mt-2 mb-6">
      <div className="text-lg md:mb-2 font-bold opacity-50 md:pb-0 pb-3">{question}</div>
      <div>
        <RichText document={answer} />
      </div>
    </div>
  );
}
