import { ReactElement } from 'react';

import { IComponentFrequentlyAskedQuestionsFields } from 'types/contentful';
import FaqMultipleGroupingsMaterial from '../UI/FrequentlyAskedQuestions/FaqMultipleGrouping';
import FaqSingleGrouping from '../UI/FrequentlyAskedQuestions/FaqSingleGrouping';
import FaqData from 'components/StructuredData/FaqData';

export default function FrequentlyAskedQuestions({
  heading,
  faqGroupings,
  cta,
}: IComponentFrequentlyAskedQuestionsFields): ReactElement {
  return (
    <>
      {/* Structured Data */}
      <FaqData faqs={faqGroupings} />

      {faqGroupings.length === 1 ? (
        <FaqSingleGrouping heading={heading} faqGroupings={faqGroupings} cta={cta} />
      ) : (
        <FaqMultipleGroupingsMaterial faqGroupings={faqGroupings} />
      )}
    </>
  );
}
