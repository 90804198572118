import { ReactElement } from 'react';

import { IFragmentCta, IFragmentFaqGrouping } from 'types/contentful';
import FaqQuestionAnswer from './FaqQuestionAnswer';
import CTA from '../Cta';

interface FaqSingleGroupingProps {
  heading?: string;
  cta?: IFragmentCta;
  faqGroupings: IFragmentFaqGrouping[];
}

export default function FaqSingleGrouping({
  heading,
  faqGroupings,
  cta,
}: FaqSingleGroupingProps): ReactElement {
  const allFaqs = faqGroupings[0].fields.faqs.map(({ fields: { question, answer } }, index) => {
    return (
      <div className="py-1" key={index}>
        <FaqQuestionAnswer question={question} answer={answer} />
      </div>
    );
  });

  return (
    <div className="text-black lg:mx-32 md:mx-20 mx-4 lg:mt-16 mt-10">
      <h2 className="mx-1 md:pb-4 pb:2 font-heading font-medium text-2xl lg:text-4xl">{heading}</h2>
      <div>{allFaqs}</div>
      <div className="md:pb-20 pb-12 md:pt-0 pt-6">
        {cta !== undefined && <CTA {...cta.fields} />}
      </div>
    </div>
  );
}
